@font-face {
  font-family: "AvenirBook";
  font-weight: normal;
  font-style: normal;
  src: url("./AvenirBook.ttf");
}
@font-face {
  font-family: "AvenirMedium";
  font-weight: normal;
  font-style: normal;
  src: url("./AvenirMedium.ttf");
}
@font-face {
  font-family: "AvenirRoman";
  font-weight: normal;
  font-style: normal;
  src: url("./AvenirRoman.ttf");
}
